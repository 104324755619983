import Vue from 'vue';
import Vuelib from '@vuelib';

import store from '@/store';
import router from '@/router';

Vue.use(Vuelib, {
  store,
  router,
  app: {
    name: 'Access Provider',
    // Tentar sempre utilizar SVG como logo
    logo: () => import('@/assets/logo.svg'),
    version: process.env.VUE_APP_VERSION,
    primaryDark: true,
  },
});
