export default /** @type { import('vuex').Module } */ ({
  namespaced: true,

  getters: {
    profile: (state, getters, rootState, rootGetters) => rootGetters['accessProvider/profile'],
    profileMasterTag: (state, getters, rootState, rootGetters) => rootGetters['accessProvider/profileMasterTag'],
  },

  actions: {
    async signOut({ dispatch }) {
      await dispatch('accessProvider/users/logout', null, { root: true });
    },

    async changePassword({ dispatch }, data) {
      await dispatch('accessProvider/users/changePassword', data, { root: true });
    },

    async changeAvatar({ dispatch, getters }, { base64 }) {
      await dispatch('accessProvider/users/fetchUpdate', {
        id: getters.profile.id,
        avatar: base64,
      }, { root: true });
    },
  },
});
