import { VlLayoutDefault } from '@vuelib/layouts';
import Vue from 'vue';
import VueRouter from 'vue-router';
import companies from './companies';
import services from './services';
import store from '@/store'; // eslint-disable-line
import users from './users';
import vuetify from '@/plugins/vuetify'; // eslint-disable-line

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    } else if (to.name === from.name) {
      scrollTo = document.documentElement.scrollTop;
    }

    return vuetify.framework.goTo(scrollTo);
  },

  routes: [
    {
      path: '/',
      component: VlLayoutDefault,
      children: [
        {
          path: '',
          name: 'dashboard',
          component: () => import(/* webpackChunkName: "dashboard" */ '@/views/index.vue'),
        },
        {
          path: 'profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "profile" */ '@/views/profile.vue'),
        },
        companies,
        users,
        services,
      ],
    },

    {
      path: '*',
      redirect: '/',
    },
  ],
});

const oldPush = router.push;
router.push = function newRouterPush(...[route, ...args]) {
  if (route?.mouseEvent instanceof MouseEvent) {
    const $event = route.mouseEvent;
    const { href } = router.resolve(route);

    if ($event.ctrlKey || $event.shiftKey || $event.which === 2) {
      $event.stopPropagation();
      window.open(href, $event.shiftKey ? '_blank' : null);
    }

    return Promise.resolve();
  }
  return oldPush.apply(this, [route, ...args]);
};

router.beforeEach((to, from, next) => {
  if (to && to.meta && to.meta.roles) {
    const profile = store.getters['auth/profile'];
    if (profile && profile.tags) {
      let validEntry = false;
      profile.tags.every((tag) => {
        to.meta.roles.every((role) => {
          if (tag.description === role) validEntry = true;
          return !validEntry;
        });
        return !validEntry;
      });

      if (validEntry) next();
      else next({ path: '/' });
    } else {
      next({ path: '/' });
    }
  } else {
    next();
  }
});

window.router = router;
export default router;
