import { VlRouteBase } from '@vuelib/components';

export default /** @type { import('vue-router').RouteConfig } */ ({
  path: 'services',
  component: VlRouteBase,
  children: [
    {
      path: '',
      name: 'services',
      component: () => import(/* webpackChunkName: "services" */ '@/views/services/index.vue'),
      meta: {
        roles: ['admin', 'developer', 'support'],
      },
    },

    {
      path: ':serviceId',
      name: 'services.id',
      meta: {
        backTo: ({ $route }) => ({ name: 'services', params: $route.params }),
        roles: ['admin', 'developer', 'support'],
      },
      component: () => import(/* webpackChunkName: "services" */ '@/views/services/_id.vue'),
    },
  ],
});
