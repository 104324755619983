import Vue from 'vue';
import Vuex from 'vuex';
import createAccessProviderModule from '@produtos/store-access-provider';
import createSettingProviderModule from '@produtos/store-setting-provider';
import databaseLoopbackTransformer from '@i9box/vuelib/src/utils/datatable-loopback-transformer';

import vuelib from '@vuelib/store';

import app from './modules/app';
import auth from './modules/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // padrão vuelib
    vuelib,

    // padrão aplicação
    app,
    auth,

    // provider
    settingProvider: createSettingProviderModule(),
    accessProvider: createAccessProviderModule({
      domainAction: 'settingProvider/$setup',
      pinGetter: 'settingProvider/pin',
      fetchAllAdapter: databaseLoopbackTransformer,
    }),
  },
});
