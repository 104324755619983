import isCompanyUser from '@produtos/store-access-provider/src/utils/is-company-user';
import isInnerUser from '@produtos/store-access-provider/src/utils/is-inner-user';

export default /** @type { import('vuex').Module } */ ({
  namespaced: true,

  state: {
    menus: {
      dashboard: {
        icon: 'mdi-view-dashboard-outline',
        label: 'Dashboard',
        to: '/',
      },
      companies: {
        icon: 'mdi-home-city-outline',
        label: 'Companies',
        to: '/companies',
      },
      users: {
        icon: 'mdi-account-group-outline',
        label: 'Users',
        to: { name: 'users', params: { companyId: null } },
      },
      services: {
        icon: 'mdi-hexagon-multiple-outline',
        label: 'Services',
        to: '/services',
      },
    },
  },

  getters: {
    services: (state, getters, rootState, rootGetters) => rootGetters['accessProvider/services'],
    menus: (state, getters, rootState, rootGetters) => {
      const profile = rootGetters['auth/profile'];

      if (isCompanyUser(profile?.tags)) {
        return [
          // state.menus.dashboard,
          {
            ...state.menus.users,
            to: {
              ...state.menus.users.to,
              params: { companyId: profile.company.id },
            },
          },
        ];
      }

      if (isInnerUser(profile?.tags)) {
        return [
          // state.menus.dashboard,
          state.menus.companies,
          state.menus.services,
        ];
      }

      return [
        // state.menus.dashboard,
        // state.menus.services,
      ];
    },
  },
});
