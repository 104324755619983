import { VlRouteBase } from '@vuelib/components';

export default /** @type { import('vue-router').RouteConfig } */ ({
  path: 'users',
  component: VlRouteBase,
  children: [
    {
      path: '',
      name: 'users',
      component: () => import(/* webpackChunkName: "users" */ '@/views/users/index.vue'),
      meta: {
        roles: ['admin', 'company', 'developer', 'support'],
      },
    },

    {
      path: 'users/:userId',
      name: 'users.id',
      meta: {
        backTo: ({ $route }) => ({ name: 'users', params: $route.params }),
        roles: ['admin', 'company', 'developer', 'support'],
      },
      component: () => import(/* webpackChunkName: "users" */ '@/views/users/_id.vue'),
    },
  ],
});
