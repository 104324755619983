import VuelibVuetifyTheme from '@vuelib/vuetify-theme';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import '@mdi/font/css/materialdesignicons.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import theme from '@/constants/theme';
import colors from 'vuetify/lib/util/colors';

import i18n from './i18n';

Vue.use(Vuetify);

export default new Vuetify({
  ...VuelibVuetifyTheme(process.env.VUE_APP_VERSION, {
    ...theme,
    error: colors.red.accent2,
    info: colors.lightBlue.accent2,
    success: colors.green.accent2,
    warning: colors.amber.accent2,
  }),
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});
