import './plugins/vuelib';
import './filters/cnpj';
import './filters/white-space';
import './filters/pluralize';

import Vue from 'vue';
import i18n, { setupLanguage } from './plugins/i18n';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Promise.all([
  store.dispatch('settingProvider/$setup'),
  store.dispatch('accessProvider/$setup'),
  () => {
    if (!navigator.serviceWorker) return null;
    return import('./registerServiceWorker');
  },
  setupLanguage(Array.from(navigator.languages)),
])
  .then(() => {
    new Vue({
      router,
      store,
      i18n,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  });
